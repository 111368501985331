import React from "react";
import  { useEffect } from "react";
import ReactDOM from "react-dom";
import { Pannellum } from "pannellum-react";
import myImage from "../image/akash3.jpeg";
import "./style.css"
function PannellumReact() {
  function handelRightClick(event) {
    event.preventDefault();
    console.log("you are click rightClick")
  }
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <div >
      <Pannellum
      className="contextmenu"
        width="100%"
        height="100vh"
        image={myImage}
        pitch={0}
        yaw={0}
        hfov={110}
        title="Xane AI"
        autoLoad
        showZoomCtrl={false}
        URL={false}
      >    
        <Pannellum.Hotspot
        type="info"
        pitch={-15}
        yaw={-25}
        text="Steering Wheel"
        URL="/"
      />
     
    <Pannellum.Hotspot
    type="info"
    pitch={-10}
    yaw={11}
    text="Air Conditioning System "
    URL="/"
  />
  <Pannellum.Hotspot
  type="info"
  pitch={10}
  yaw={-1}
  text="Rearview Mirrors"
  URL="/"
/>
      </Pannellum>
    </div>
  );
}
export default PannellumReact;